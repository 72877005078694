<template>
	<div class="maincontainer">
		<div @click="back" class="navbar">
			<div class="navback center">
				<van-icon name="arrow-left" />
			</div>
			<span class="pagetitle">诸葛测名结果页</span>
		</div>
		<div class="center">
			<ul class="ordernavbox ulbox">
				<li v-for="(value, index) in nameinfo.base.pinyin" :key="value">
					<p>{{ value }}</p>
					<span>{{ nameinfo.base.name[index] }}</span>
					<i>[{{ nameinfo.base.asktaoval[index] }}]</i>
				</li>
			</ul>
		</div>
		<div class="center rateb">
			姓名测试综合评分
		</div>
		<div class="rsta center" style="margin-top: 15px;">
			<span
				style="font-size: 50px;font-weight: bold;line-height: 1em;color: #C94B4B;">{{nameinfo.score.avg}}
			</span><span style="align-self: flex-end;line-height: 1.8em;font-size: 16px; font-weight: bold;">分</span>
		</div>

		<div class="pingfenProgress">
			<ul class="pfbox">
				<li>
					<p>
						八字五行<b class="percent" style="color: rgb(20, 183, 165);">{{ nameinfo.score.one }}分</b>
					</p>
					<div class=" bfb">
						<i class="back_b0d9e1" v-bind:style="{width: nameinfo.score.one + '%'}"></i>
					</div>
				</li>
				<li v-if="nameinfo.score.two > 0">
					<p>
						三才五格<b class="percent" style="color: rgb(20, 183, 165);">{{ nameinfo.score.two }}分</b>
					</p>
					<div class=" bfb">
						<i class="back_b0d9e2" v-bind:style="{width: nameinfo.score.two + '%'}"></i>
					</div>
				</li>
			</ul>
			<div class="center" style="width: 80%;margin:10px auto;font-size: 12px;color: #979797;line-height: 2em;">
				温馨提醒：不怕取错名，就怕不正确了解名字！诸葛测名网从易经八字和三才五格两大专业角度，科学分析您的名字评分，帮您正确了解自身名字优劣，对未来发展起到一定指导性作用。
			</div>
		</div>

		<div class="article_title">
			基本信息
		</div>
		<div class="center">
			<ul class="ordernavbox ulbox" style="margin: 0;">
				<li style="height: 80px;padding-top: 0;" v-for="(value, index) in nameinfo.base.pinyin" :key="value">
					<span>{{ nameinfo.base.name[index] }}</span>
				</li>
			</ul>
		</div>
		<table border="0" cellspacing="1" cellpadding="0" class="newdetailed"
			style="border-spacing:1px;border-collapse:separate">
			<tbody>
				<tr>
					<td>性别</td>
					<td>{{nameinfo.base.sex}}</td>
				</tr>
				<tr>
					<td>生日</td>
					<td>
						公历 {{nameinfo.bDate[0]}}年 {{nameinfo.bDate[1]}}月 {{nameinfo.bDate[2]}}日  {{nameinfo.bDate[3]}}点 {{nameinfo.bDate[4]}}分<br />
						农历 {{ nameinfo.nongli.gzYear}}年（{{ nameinfo.nongli.yearCn }}年{{
                        nameinfo.nongli.monthCn + nameinfo.nongli.dayCn
                        }}）-{{nameinfo.base.moon_time.split("#")[1]}}
					</td>
				</tr>
				<tr>
					<td>属相</td>
					<td>属{{nameinfo.base.sx}}</td>
				</tr>
				<tr>
					<td></td>
					<td style="color: #979797;">注：晚23:00出生后在古代属于子时，属于新的一天。</td>
				</tr>
			</tbody>
		</table>
		<div class="article_title">
			综合评分简析
		</div>
		<ul class="jianpin">
			<li>
				<div class="jianptitle">
					<span class="tt">八字分析</span>
					<span class="aa">{{ nameinfo.score.one}}<span style="font-size: 12px;margin-left: 5px;">分</span></span>
				</div>
				<div class="jianpdesc">
					<div class="names">
						【<span style="color: #C94B4B;font-weight: 500;">{{nameinfo.base.name}}</span>】
					</div>
					<div class="dsss" v-html="nameinfo.asktao_style_text.style_bazi_text">

					</div>
				</div>
			</li>
			<li>
				<div class="jianptitle">
					<span class="tt">三才五格</span>
					<span v-if="nameinfo.score.two" class="aa">{{nameinfo.score.two}}<span style="font-size: 12px;margin-left: 5px;">分</span></span>
				</div>
				<div class="jianpdesc">
					<div class="names">
						【<span style="color: #C94B4B;font-weight: 500;">{{nameinfo.base.name}}</span>】
					</div>
					<div class="dsss" >
						<p v-html="nameinfo.scwg.scwg_text"> </p> <br />
						<p v-html="nameinfo.asktao_style_text.style_scwg_text"> </p>
					</div>
				</div>
			</li>
			<li>
				<div class="jianptitle">
					<span class="tt">生肖分析</span>
				</div>
				<div class="jianpdesc">
					<div class="names">
						【<span style="color: #C94B4B;font-weight: 500;">{{nameinfo.base.name}}</span>】
					</div>
					<div class="dsss">
						<p v-html="nameinfo.sx_text.sx_title"></p><br />
						<p v-html="nameinfo.sx_text.sx_mean"></p><br />
						<p v-html="nameinfo.sx_text.sx_word"></p>
						
					</div>
				</div>
			</li>
			<li>
				<div class="jianptitle">
					<span class="tt">汉字音韵</span>
				</div>
				<div class="jianpdesc">
					<div class="names">
						【<span style="color: #C94B4B;font-weight: 500;">{{nameinfo.base.name}}</span>】
					</div>
					<div class="dsss">
						<template v-for="(item,index) in nameinfo.hanzimean.xm">
							【{{item}}】读音为（{{nameinfo.hanzimean.pinyin[index]}}），声调为（{{nameinfo.yl.val[index]}}）<br />
						</template>
						<br />
						{{nameinfo.base.name}}，音韵组合为：{{nameinfo.yl.val.join('、')}}，{{nameinfo.yl.mean}}
						
					</div>
				</div>
			</li>
			<li>
				<div class="jianptitle">
					<span class="tt">汉字意形</span>
				</div>
				<div class="jianpdesc">
					<div class="names">
						【<span style="color: #C94B4B;font-weight: 500;">{{nameinfo.base.name}}</span>】
					</div>
					<div class="dsss">
						<template v-for="(value,key,index) in nameinfo.base.means">
							<template  v-if="value.value.indexOf('暂无') == -1" >
								【{{value.key}}】{{value.value.slice(3,-1)}}  <br/>
							</template>
						</template>
						

				<!-- 		【文】字体结构为：独体结构<br />
						【雅】字体结构为：左中右结构<br /><br /> -->

						该名字字型简洁端正，高山流水，脚踏实地，积极向上。
					</div>
				</div>
			</li>
		</ul>
		<div class="article_title">
			八字分析
		</div>
		<dl>
			<dt class="dlttt">您的八字命盘</dt>
			<dd>
				<Paipan :sex="nameinfo.sex == 1 ? '男' : '女'" :data="nameinfo.eight_chara" />
			</dd>
			<dt class="dlttt">八字命格分析</dt>
			<!-- <dd v-html="nameinfo.asktao_style_text.style_unique"></dd> -->
			<dd>
				<div class="gejuetype">
					<span class="fontw">·</span>{{nameinfo.ask_tao.asktao_style}}格局
				</div>
				<div v-html="nameinfo.asktao_style_text.style_zl">
				</div>	
			</dd>
			<dd v-html="nameinfo.asktao_style_text.style_unique"></dd>
		</dl>

		<div class="article_title">
			对你有利的五行
		</div>
		<div class="wuxing">
			<h5 style="margin: 6px 0;line-height: 24px;padding:0;font-size: 16px;">五行旺弱分析</h5>
			<p style="margin-top: 6px;line-height: 24px;">您的日主天干属<span class="color-mainred">{{nameinfo.ask_tao.benming}}</span>，经计算，您的八字同类五行是土、金。
			</p>
			<div class="center box"><img style="width: 70%;"
					src="//cdn.names.org.cn/website/Content/szname/img/WxGif2.gif"></div>
			<h5 style="margin: 6px 0;line-height: 24px;padding:0;font-size: 16px;">五行旺弱</h5>
			<WuxingHl :score="nameinfo.ask_tao.qk_score" :qk_score_ratio="nameinfo.ask_tao.qk_score_ratio" />
			<div class="txt ">
				同类含量：{{ WW[0] }} + {{ WW[1] }}＝{{ WWrd }}
			</div>
			<div class="txt ">
				异类含量：{{ DW[0] }} ＋ {{ DW[1] }} ＋{{ DW[2] }}＝{{ DWrd }}
			</div>
			<div class="txt ">综合旺弱判定</div>
			<div class="txt ">
				同类含量/异类含量＝{{ (WWrd / DWrd).toFixed(2) }}
			</div>
			<br />
			<p class="color-mainred">经过对八字、五行力量及季节力量的综合分析的命格取（{{XYS}}）为喜用五行。</p>
			<p>
				<template
					v-for="(item,index) in nameinfo.base.name">【{{item}}】字五行属（{{nameinfo.base.asktaoval[index]}}），是对你（{{XYS.indexOf(nameinfo.base.asktaoval[index]) > -1?'有利':'不利'}}）的五行<br /></template>
			</p>


			<div class="txt" style="margin-top:18px">
				综合该八字五行喜用分析和五行平衡，起名时用五行属于
				<span v-for="item in XYS" :key="index" class="color-red">【{{ item }}】</span>
				的字，最有利宝宝一生发展，生生不息，幸福长久，有志者事必成。
			</div>
		</div>
		<div class="article_title">
			汉字音韵
		</div>
		<table class="tbbox">
			<tbody>
				<tr word="1">
					<th></th>
					<td v-for="item in nameinfo.hanzimean.xm" :key="item">
						<div class="zi">{{ item }}</div>
					</td>
				</tr>
				<tr pinying="1">
					<th>拼音</th>
					<td v-for="item in nameinfo.hanzimean.pinyin" :key="item">
						{{ item }}
					</td>
				</tr>
				<tr kangxibh="1">
					<th>声母组合</th>
					<td v-for="item in nameinfo.yl.sm" :key="item">
						{{ item }}
					</td>
				</tr>
				<tr wuxing="1">
					<th>韵母组合</th>
					<td v-for="(value,index) in nameinfo.yl.ym" :key="index">
						{{ value }}
					</td>
				</tr>
				<tr bushou="1">
					<th>声调组合</th>
					<td v-for="item in nameinfo.yl.val" :key="item">
						{{ item }}
					</td>
				</tr>
			</tbody>
		</table>
		<br />
		<p class="mingzilist">
			<template v-for="(item,index) in nameinfo.hanzimean.xm">
				【{{item}}】读音为（{{nameinfo.hanzimean.pinyin[index]}}），声调为（{{nameinfo.yl.val[index]}}）<br />
			</template>
			<br />
			{{nameinfo.hanzimean.xm}}， 音韵组合为{{nameinfo.yl.val.join("、")}}， {{nameinfo.yl.mean}}
		</p>
		<div class="article_title">汉字意形</div>
		<table class="tbbox">
			<tbody>
				<tr word="1">
					<th>姓名</th>
					<td v-for="item in nameinfo.hanzimean.xm" :key="item">
						<div class="zi">{{ item }}</div>
					</td>
				</tr>
				<tr word="1">
					<th>繁体</th>
					<td v-for="item in nameinfo.hanzimean.ft" :key="item">
						<div class="zi">{{ item }}</div>
					</td>
				</tr>
				<tr pinying="1">
					<th>拼音</th>
					<td v-for="item in nameinfo.hanzimean.pinyin" :key="item">
						{{ item }}
					</td>
				</tr>
				<tr kangxibh="1">
					<th>康熙笔画</th>
					<td v-for="item in nameinfo.hanzimean.kxbihua" :key="item">
						{{ item }}
					</td>
				</tr>
				<tr wuxing="1">
					<th>五行</th>
					<td v-for="(value,index) in nameinfo.hanzimean.wx" :key="index">
						{{ value }}
					</td>
				</tr>
				<!-- 		<tr bushou="1">
					<th>声调组合</th>
					<td v-for="item in nameinfo.yl.val" :key="item">
						{{ item }}
					</td>
				</tr> -->
			</tbody>
		</table>
		<br />
		<p class="mingzilist">
			<template v-for="item in nameinfo.base.means">
				<template v-if="item.value.indexOf('暂无') == -1">
					【{{ item.key }}】{{ item.value }}<br />
				</template>
			</template>
		</p>
		<div class="article_title">三才五格</div>
		<dl>
			<dd class="lh2e wgbox">
				<ul class="wg-list">
					<li>
						<div class="wg-unit">
							外格<span class="wg-num">{{ nameinfo.scwg.fiveGirl["外"]}}</span>
						</div>
					</li>
					<li scwg-name="1">
						<div v-for="(value, index) in nameinfo.hanzimean.xm" :key="value" class="wg-unit">
							<strong>{{ value }}</strong><span class="wg-num">{{nameinfo.hanzimean.kxbihua[index]}}</span>
						</div>
					</li>
					<li>
						<div class="wg-unit">
							天格<span class="wg-num">{{nameinfo.scwg.fiveGirl["天"] }}</span>
						</div>
						<div class="wg-unit">
							人格<span class="wg-num">{{ nameinfo.scwg.fiveGirl["人"] }}</span>
						</div>
						<div class="wg-unit">
							地格<span class="wg-num">{{ nameinfo.scwg.fiveGirl["地"] }}</span>
						</div>
					</li>
				</ul>
				<table class="tbtxt">
					<colgroup>
						<col width="26%" />
					</colgroup>
					<tbody>
						<tr>
							<td>【<strong>{{nameinfo.base.name}}</strong>】</td>
							<td>
								<p style="margin: 0;padding: 0;">
									五格：天格<strong>{{ nameinfo.scwg.fiveGirl["天"] }}</strong>，地格<strong>{{ nameinfo.scwg.fiveGirl["地"] }}</strong>，外格<strong>{{ nameinfo.scwg.fiveGirl["外"] }}</strong>，人格<strong>{{ nameinfo.scwg.fiveGirl["人"] }}</strong>，总格<strong>{{ nameinfo.scwg.fiveGirl["总"] }}</strong>
								</p>
							</td>
						</tr>
					</tbody>
				</table>
			</dd>
		</dl>
		<dl>
			<dt style="margin: 15px 0; font-size: 16px;font-weight: 500;">五格数理</dt>
			<dd>
				<ul>
					<li class="shuli">
						<div class="sut">
							天格<span>{{ nameinfo.scwg.fiveGirl["天"] }}</span>
						</div>
						<div>
							[影响]12岁前的先天运势以及先天遗传，影响不大，若不理想不必计较
						</div>
						<div>[总论]{{ nameinfo.scwg.fiveNum["天"].mean }}</div>
					</li>
					<li class="shuli">
						<div class="sut">
							人格<span>{{ nameinfo.scwg.fiveGirl["人"] }}</span>
						</div>
						<div>
							[影响]24-48岁人生颠峰期运势以及个性、才华及事业运，影响一生运势
						</div>
						<div>[总论]{{ nameinfo.scwg.fiveNum["人"].mean }}</div>
					</li>
					<li class="shuli">
						<div class="sut">
							地格<span>{{ nameinfo.scwg.fiveGirl["地"] }}</span>
						</div>
						<div>
							[影响]36岁前的青年运势以及家庭夫妻、子女田宅，影响基础运
						</div>
						<div>[总论]{{ nameinfo.scwg.fiveNum["地"].mean }}</div>
					</li>
					<li class="shuli">
						<div class="sut">
							外格<span>{{ nameinfo.scwg.fiveGirl["外"] }}</span>
						</div>
						<div>
							[影响]36-48岁的中年运势以及社交、朋友、工作环境等，影响后天的机遇
						</div>
						<div>[总论]{{ nameinfo.scwg.fiveNum["外"].mean }}</div>
					</li>
					<li class="shuli">
						<div class="sut">
							总格<span>{{ nameinfo.scwg.fiveGirl["总"] }}</span>
						</div>
						<div>
							[影响]48岁后的中晚年运势以及财富、收入，影响人生最终的成就
						</div>
						<div>[总论]{{ nameinfo.scwg.fiveNum["总"].mean }}</div>
					</li>
				</ul>
			</dd>
		</dl>
		<div class="article_title">三才配置</div>
		<dl >
			<dd class="nameDetail">
				<div class="scImg">
					<div class="top">
						<p class="scItem"><i class="gre">天</i>才</p>
						<p class="wxT">
							[<i>{{ nameinfo.scwg.sanCai["天"] }}</i>]
						</p>
					</div>
					<div class="nameCenter">
						<ul class="duyin">
							<li v-for="item in nameinfo.hanzimean.pinyin" :key="item">
								{{ item }}
							</li>
						</ul>
						<ul class="name">
							<li v-for="item in nameinfo.hanzimean.xm" :key="item">
								{{ item }}
							</li>
						</ul>
					</div>
					<div class="left">
						<p style="margin: 0; padding: 0;" class="scItem"><i class="di">地</i>才</p>
						<p class="wxT">
							[<i>{{ nameinfo.scwg.sanCai["地"] }}</i>]
						</p>
					</div>
					<div class="right">
						<p class="scItem"><i class="ren">人</i>才</p>
						<p class="wxT">
							[<i>{{ nameinfo.scwg.sanCai["人"] }}</i>]
						</p>
					</div>
				</div>
			<!-- 	<p class="lh2e">
					三才配置：<span v-for="(value, key) in nameinfo.scwg.sanCai" :key="key"
						class="color-red">{{ value }}</span>
					&nbsp;此配置者发展之路艰难险阻颇多，皆因
					基础不稳，无法获得帮扶，要想成功，唯有付出比别人更多 的努力。
				</p> -->
			</dd>
			<dd style="font-size:12px;color:#979797;ling-height:1.5em;margin-top:0px">
				注：以上内容来自传统民俗文化分析，结果仅供参考。
			</dd>
		</dl>
		<div class="article_title">生肖分析</div>
		<dl>
			<dd>
				<img style="width:100%" :src="'https://cdn.zhiyileiju.cn/' + shu[nameinfo.base.sx] + '.jpg'" />
			</dd>
		</dl>
	</div>

</template>

<script>
	import {
		Icon
	} from 'vant';
	import axios from "../utils/request.js"
	import Paipan from "../components/Paipan.vue";
	import WuxingHl from "../components/WxHanLg.vue";
	import solarLunar from "../components/datepick/solarlunar";
	import report from "../utils/stat.js";
	export default {
		components: {
			vanIcon: Icon,
			Paipan,
			WuxingHl,
		},
		data() {
			return {
				nameinfo: {
					base: {
						id: 29,
						pinyin: ["lín", "wèi"],
						name: "林渭",
						mname: "渭",
						asktaoval: "木水",
						means: [{
							key: "渭",
							value: "字意：水名，即渭河，发源于甘肃省渭源，流到陕西省汇同泾河入黄河。",
						}, ],
						sx: "牛",
						xz: "巨蟹座",
						name_repeat: 139,
					},
					asktao_style_text: {},
					score: {
						avg: 98,
						one: 96,
						two: 98,
						three: 99,
						four: 98,
						five: 96,
						sex: 98,
					},
					hanzimean: {
						xm: ""
					},
					yl: {
						val: "",
						ym: "",
						sm: ""
					}
				}
				,
				shu: {
					狗: "gou",
					猴: "hou",
					虎: "hu",
					鸡: "ji",
					龙: "long",
					马: "ma",
					牛: "niu",
					蛇: "she",
					兔: "tu",
					羊: "yang",
					猪: "zhu",
					鼠: "shu"
				},
			}

		},
		created() {
			this.orderid = this.$route.params.id;
			this.getInfo(this.orderid);
			report("v1_testnameresult");
			document.title = "诸葛国学测名";
			
		},
		computed: {
			WW() {
				return Object.keys(this.nameinfo.ask_tao.ww_score_ratio);
			},
			DW() {
				return Object.keys(this.nameinfo.ask_tao.dw_score_ratio);
			},
			WWrd() {
				return Object.keys(this.nameinfo.ask_tao.ww_score_ratio)
					.reduce(
						(a, b) =>
						this.nameinfo.ask_tao.qk_score[a] +
						this.nameinfo.ask_tao.qk_score[b]
					)
					.toFixed(1);
			},
			XYS() {
				return Object.keys(this.nameinfo.ask_tao.asktao_result['喜用']).join("");
			},
			DWrd() {
				let a = 0;
				Object.keys(this.nameinfo.ask_tao.dw_score_ratio).forEach((key) => {
					a = a + this.nameinfo.ask_tao.qk_score[key];
				});
				return a.toFixed(1);
			}
		},
		methods: {
			back(){
				this.$router.back(-1)
			},
			async getInfo(orderid) {
				let data = await axios.post("/orderNameDetail", {
					order_id: orderid,
					name_id: 0
				});
				let nameinfo = data.data.data;
				nameinfo.base.pinyin = nameinfo.base.pinyin.split(",");
				let temp = [];
				Object.keys(nameinfo.base.means).forEach((key) => {
					temp.push({
						key,
						value: nameinfo.base.means[key],
					});
				});
				let birth =nameinfo.base.birth_date;
				
				let fdate = birth.split(" ");

				nameinfo.bDate = [...fdate[0].split("-"), ...fdate[1].split(":")];

				nameinfo.nongli = solarLunar.solar2lunar(
				    nameinfo.bDate[0],
				    nameinfo.bDate[1],
					nameinfo.bDate[2]
				);

				let bh = nameinfo.hanzimean.kxbihua.slice(0, -1);
				let py = nameinfo.hanzimean.pinyin.slice(0, -1);

				nameinfo.hanzimean.kxbihua = bh.split("#");
				nameinfo.hanzimean.pinyin = py.split("#");

				nameinfo.yl.sm = nameinfo.yl.sm.split("#");
				nameinfo.yl.ym = nameinfo.yl.ym.split("#");
				nameinfo.yl.val = nameinfo.yl.val.split(",");

				nameinfo.base.means = temp;

				this.nameinfo = nameinfo;
				this.nameid = nameinfo.base.id
			}
		}
	}
</script>
<style scoped>
	@import "../style/main.css";
</style>

<style lang="less" scoped>
	*{
		margin: 0;
		padding: 0;
	}
	.maincontainer {
		padding-top: 37px;
		padding-bottom: 60px;
		width: 100%;
		max-width: 640px;
		background-color: #fff;
		// min-height: 100vh;
	}

	.color-mainred {
		color: #C94B4B;
	}

	.navbar {
		height: 37px;
		width: 100%;
		max-width: 640px;
		background-color: #C94B4B;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		position: fixed;
		top: 0;
		z-index: 10000;

		.navback {
			width: 37px;
			height: 37px;
		}

		.pagetitle {
			display: inline-block;
			width: calc(100% - 37px);
			text-align: center;
		}
	}

	.rateb {
		margin-top: 24px;
		font-size: 16px;
		font-weight: 500;
	}

	.pingfenProgress {
		width: calc(100% - 30px);
		margin-left: 15px;

		.pfbox {
			display: flex;
			justify-content: space-around;
		}

		li {
			margin: 5px 0;
			font-size: 10px;
			color: #666;
			text-align: center;
			width: 32%;

			p {
				padding-bottom: 5px;
			}

			b {
				padding-left: 3px;
				color: #333;
				font-size: 13px;
			}

			.bfb,
			.bfb i {
				height: 10px;
				border-radius: 10px;
				display: block;
			}

			.bfb {
				background: #e4e4e4;
			}

			.back_b0d9e1 {
				background: #7FE4AF;
			}

			.back_b0d9e2 {
				background: #9CCCF6;
			}
		}
	}

	.article_title {
		height: 25px;
		line-height: 25px;
		padding-left: 14px;
		border-left: 5px solid #C94B4B;
		font-size: 18px;
		font-weight: 500;
		color: #C94B4B;
		margin: 25px 0;
	}

	.newdetailed {
		width: calc(100% - 40px);
		margin: 0 auto;
		background: #c09d65;

		tr {
			height: 40px;

			td {
				background: #fff;
				padding: 12px;
				font-size: 12px;
				color: #020202;
			}
		}
	}

	.newdetailed tr td:first-child {
		background: #f2eedf;
		text-align: center;
		width: 20%;
	}

	.jianpin {
		padding: 0 20px;
		box-sizing: border-box;

		li {
			padding-bottom: 20px;

			.jianptitle {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
				width: 100%;
				border-bottom: 1px solid #C94B4B;

				.tt {
					display: block;
					padding: 0 18px;
					height: 32px;
					background: #C94B4B;
					border-radius: 10px 10px 0px 0px;
					line-height: 32px;
					color: #FFFFFF;
					font-weight: 500;
					text-align: center;
				}

				.aa {
					align-self: flex-end;
					color: #C94B4B;
					font-weight: 400;
					font-size: 24px;
				}
			}

			.jianpdesc {
				display: flex;
				padding-top: 20px;

				.names {
					width: 15%;
					min-width: 80px;
				}

				.dsss {
					flex-grow: 1;
					line-height: 24px;
					color: #323232;
				}
			}
		}
	}

	dl {
		padding: 0 20px;

		.dlttt {
			margin: 20px 0;
			font-size: 16px;
			font-weight: 500;
		}

		dd {
			margin: 0;

			/deep/ .gejuetype {
				border-bottom: 1px solid #C94B4B;
				display: inline-block;
				font-weight: 500;
				line-height: 1.8em;
				margin-bottom: 14px;
			}

			line-height: 24px;
		}
	}

	.wuxing {
		padding: 0 20px;
		line-height: 24px;

		.txt {

			color: #323232;
		}
	}

	@highlightColor: #b95353;

	.tbbox {
		margin: 0 20px;
		width: calc(100% - 40px);
		font-size: 13px;
		text-align: center;
		border-collapse: collapse;
		border-spacing: 0;

		td {
			padding: 7px 5px;
			text-align: left;
			border: 1px solid #ccc;
			text-align: center;
		}

		th {
			text-align: center;
			color: @highlightColor;
			background-color: #fff;
			border: 1px solid #ccc;
		}

	}


	.tbbox th,
	.tbbox td {
		border: 1px solid #ccc;
	}

	.tbbox th {
		padding: 5px 0;
		color: #b95353;
		background-color: #FFF7EC;
	}

	.tbbox td {
		padding: 10px;
	}

	.tbbox tfoot {
		font-size: 12px;
		text-align: left;
		color: #999;
	}

	.mingzilist {
		line-height: 24px;
		padding: 0 20px;
	}
	
	@siteColor: #b95353;
	.wgbox {
		padding: 15px;
		text-align: center;
	
		li {
			display: inline-block;
			vertical-align: middle;
			margin-right: 20px;
			border-left: 1px solid @siteColor;
		}
	
		.wg-unit {
			margin: 10px 0;
	
			&::before {
				display: inline-block;
				vertical-align: middle;
				width: 15px;
				height: 1px;
				content: "";
				background-color: @siteColor;
			}
		}
	
		.wg-num {
			display: inline-block;
			vertical-align: middle;
			margin-left: 5px;
			width: 25px;
			height: 25px;
			line-height: 25px;
			color: @highlightColor;
			background-color: @siteColor;
			color: #fff;
		}
	
		h4 {
			margin-top: 25px;
			font-weight: bold;
			text-align: center;
		}
	}
	.tbtxt {
		margin-top: 15px;
		width: 100%;
		font-size: 13px;
		text-align: left;
	
		th,
		td {
			vertical-align: top;
			padding: 5px;
		}
	
		strong {
			color: @siteColor;
		}
	
		.tb-caption {
			font-weight: bold;
			text-align: left;
			color: #000;
	
			&:before {
				display: inline-block;
				vertical-align: middle;
				margin-right: 5px;
				width: 3px;
				height: 3px;
				content: "";
				background-color: #333;
				border-radius: 50%;
			}
		}
	}
	
	.shuli {
		font-size: 14px;
		font-family: Microsoft YaHei;
		line-height: 2em;
		margin-bottom: 8px;
	
		.sut {
			font-size: 14px;
			color: #020202;
			line-height: 28px;
	
			span {
				margin: 0 5px;
			}
		}
	}
	
	
	.nameDetail .scImg {
		width: 100%;
		height: 300px;
		margin: 30px 0;
		background: url(//cdn.zhiyileiju.cn/qwewewqew.png) no-repeat center 40px;
		background-size: 250px;
	}
	
	
	.nameDetail .nameCenter {
		text-align: center;
		padding-top: 25px;
		font-size: 0;
	}
	
	.nameDetail .nameCenter .name li,
	.nameDetail .nameCenter .duyin li {
		display: inline-block;
		min-width: 60px;
		height: 30px;
		font-size: 32px;
		color: #333;
		text-align: center;
		line-height: 30px;
		font-weight: bold;
	}
	
	.nameDetail .nameCenter .duyin li {
		font-size: 24px;
		font-weight: normal;
		letter-spacing: 1px;
		line-height: 30px;
		height: 30px;
	}
	
	.nameDetail .scImg .wxT,
	.nameDetail .scImg .wxL,
	.nameDetail .scImg .wxR {
		color: #b9a687;
		text-align: center;
	}
	
	.nameDetail .scImg .scItem {
		color: #666;
		font-weight: 500;
		display: block;
		text-align: center;
	}
	
	.nameDetail .scImg .gre,
	.nameDetail .scImg .di,
	.nameDetail .scImg .ren {
		display: inline-block;
		width: 65px;
		height: 65px;
		line-height: 65px;
		text-align: center;
		color: #fff;
		border-radius: 50px;
		font-size: 35px;
		margin-right: 4px;
	}
	
	.nameDetail .gre {
		background-color: #4dbfef;
	}
	
	.nameDetail .di {
		background-color: #84ddb6;
	}
	
	.nameDetail .ren {
		background-color: #fed165;
	}
	
	.nameDetail .scImg .left,
	.nameDetail .scImg .right {
		display: inline-block;
		margin-top: 0px;
	}
	
	.nameDetail .scImg .left {
		padding-right: 2%;
		width: 48%;
	}
	
	.nameDetail .scImg .right {
		padding-left: 8%;
		width: 39%;
	}
	
	.nameDetail .scImg .nameCenter .name {
		font-size: 0;
		line-height: 40px;
		letter-spacing: 0;
	}
	/deep/ .fontw{
		font-weight: bold;
		color: #C94B4B;
	}
	
	/deep/ .hh1{
		font-size: 16px;
		font-weight: 500;
		margin: 8px 0;
	}
	
	/deep/ .pricolor{
		color: #C94B4B;
	}
</style>
